import * as React from "react";
import { Helmet } from "@elevio/kb-kit/lib/toolkit/meta";
import PageLayout from "../components/layouts/Page";
import Header from "../components/Header";
import Footer from "../components/Footer";

const IFramePage = () => (
  <PageLayout header={<Header />} footer={<Footer />}>
    <Helmet title="Report an issue" />
    <div className="w-full h-full lg:max-w-6xl mx-auto">
      <iframe
        className="w-full h-screen"
        src="https://agency.comcate.com/crm/j4fspf7lk4"
      />
    </div>
  </PageLayout>
);

export default IFramePage;
